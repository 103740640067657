import './work.scss';

import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

import Layout from 'components/layouts/Standard';

interface Work {
  client: string;
  published: string;
  title: string;
  dates: string;
  highlightColor: string;
  image: {
    sizes: {
      full: {
        src: string;
        srcSet: string;
        sizes: string;
      };
    };
  };
  collaborators?: Array<{
    name: string;
    title?: string;
  }>;
  description: string;
  linkDescription?: string;
  linkUrl: string;
}

const WorkPage: React.FC = () => {
  const {
    allWorkYaml: { work }
  } = useStaticQuery(graphql`
    query WorkPageQuery {
      allWorkYaml(filter: { published: { eq: true } }) {
        work: nodes {
          client
          published
          title
          dates
          highlightColor
          image {
            sizes: childImageSharp {
              full: fluid(maxWidth: 1120, quality: 80) {
                src
                srcSet
                sizes
              }
            }
          }
          collaborators {
            name
            title
          }
          description
          linkDescription
          linkUrl
        }
      }
    }
  `) as {
    allWorkYaml: {
      work: Work[];
    };
  };

  return (
    <Layout
      title="Our Work &mdash; Friends of The Web"
      tagline="designs and builds websites, mobile applications, and other digital products. Below is a small selection of some of our past&nbsp;work."
    >
      <div className="work-content">
        {work.map((project) => (
          <div
            key={`${project.client}-${project.title}`}
            className="portfolio-piece"
          >
            <div className="portfolio-image">
              <img
                src={project.image.sizes.full.src}
                srcSet={project.image.sizes.full.srcSet}
                sizes={project.image.sizes.full.sizes}
              />
            </div>
            <div className="portfolio-piece-info">
              <div className="portfolio-piece-dates">{project.dates}</div>

              <div className="portfolio-piece-client">{project.client}</div>
              <div className="portfolio-piece-title">{project.title}</div>

              <hr
                className="wide"
                style={{ borderColor: project.highlightColor }}
              />

              <div className="portfolio-piece-info-wrapper">
                <p
                  className="portfolio-piece-description"
                  dangerouslySetInnerHTML={{ __html: project.description }}
                />

                <div className="portfolio-piece-meta-info">
                  {project.collaborators && (
                    <>
                      <h3>Collaborators</h3>
                      <ul className="portfolio-piece-collaborators">
                        {project.collaborators.map((collaborator) => (
                          <li key={collaborator.name}>
                            <div className="portfolio-piece-collaborator-name">
                              {collaborator.name}
                            </div>
                            {collaborator.title && (
                              <div className="portfolio-piece-collaborator-title">
                                {collaborator.title}
                              </div>
                            )}
                          </li>
                        ))}
                      </ul>
                    </>
                  )}
                  {project.linkDescription && (
                    <a className="portfolio-piece-link" href={project.linkUrl}>
                      {project.linkDescription} →
                    </a>
                  )}
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </Layout>
  );
};

export default WorkPage;
